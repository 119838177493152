<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="2">
              <!-- <input
                    v-model="searchInput"
                    type="text"
                    placeholder="Search Promocode, Company name"
                    class="form-control"
                  /> -->
            </th>

            <th colspan="3">
              <router-link
                :to="{ name: 'client.qualitycontrol.packingmaterials.create' }"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Packing material
                </div>
              </router-link>
            </th>
          </tr>
          <tr>
            <th style="width: 5%">SN</th>
            <th style="width: 20%" scope="col">Doc No</th>
            <th style="width: 20%" scope="col">Supplier</th>
            <th style="width: 25%" scope="col">Material Name</th>
            <!-- <th style="width: 15%" scope="col">Shift Incharge</th> -->
            <th style="width: 30%" scope="col">Action</th>
          </tr>
        </thead>
        <tbody v-if="!getLoadingData && filteredPromoCodes.length > 0">
          <tr v-for="(item, index) in filteredPromoCodes" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.doc_no }}</td>
            <td>{{ item.supplier }}</td>
            <td>{{ item.material_name }}</td>

            <td>
              <button
                @click="editData(item.id)"
                class="btn btn-warning btn-sm mr-3"
              >
                Edit
              </button>
              <button
                @click="ViewItem(item.id, index)"
                class="btn btn-info btn-sm"
              >
                View
              </button>
              <button
                @click="deleteItem(item.id)"
                class="btn btn-sm btn-danger ml-2"
                style="cursor: pointer"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!getLoadingData && filteredPromoCodes.length < 1">
          <tr>
            <td colspan="10" class="text-center">No record(s) found</td>
          </tr>
        </tbody>

        <tbody v-if="getLoadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
        <!----><!---->
      </table>
      <hr v-if="pagination.total_page > 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page > 1"
        :pagination="pagination"
      ></vl-pagination>
    </div>
    <packingMaterialAnalysisModal
      :key="key"
      v-if="packingMaterialAnalysisModalShow"
      v-on:closemodal="closeModal"
      :loading-data="loadingData"
      :has-data="hasData"
      :show-modal="packingMaterialAnalysisModalShow"
      :packing-material-detail="packingMaterialAnalysis"
      :index-num="currentIndex"
    ></packingMaterialAnalysisModal>
  </div>
</template>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_PACKING_MATERIAL_REPORTS,
  FETCH_SELF_PACKING_MATERIAL_REPORT,
  DELETE_PACKING_MATERIAL_REPORT,
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import vlPagination from "@/includes/table/Pagination";
import packingMaterialAnalysisModal from "@/components/frontend/modal/packingmaterialanalysisdetail";
export default {
  data() {
    return {
      key: 0,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15,
      },
      packingMaterialAnalysisModalShow: false,
      filteredPromoCodes: [],
      packingMaterialAnalysis: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Packing Material Reports",
          route: "",
        },
      ],
      loadingData: false,
      getLoadingData: false,
      hasData: false,
      searchInput: "",
    };
  },
  components: {
    vDataLoader,
    packingMaterialAnalysisModal,
    vlPagination,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    // filteredPromoCodes() {
    //   let tempPromocode =;
    //   //process search input
    //   if (this.searchInput != "" && this.searchInput) {
    //     tempPromocode = tempPromocode.filter(item => {
    //       return (
    //         item.promo_code
    //           .toUpperCase()
    //           .includes(this.searchInput.toUpperCase()) ||
    //         item.company_name
    //           .toUpperCase()
    //           .includes(this.searchInput.toUpperCase())
    //       );
    //     });
    //   }
    //   return tempPromocode;
    // }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getPromocodesData();
  },
  filters: {
    convertDateFormat(value) {
      return moment(value).format("MM/DD/YYYY");
    },
  },
  methods: {
    selectedPage(n) {
      this.pagination.current_page = n;
      this.getPromocodesData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.getPromocodesData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.getPromocodesData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.getPromocodesData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.getPromocodesData();
    },
    closeModal() {
      this.packingMaterialAnalysisModalShow = false;
    },
    editData(id) {
      this.$router.push({
        name: "client.qualitycontrol.packingmaterials.edit",
        params: { id: id },
      });
    },
    // deleteItem(id, index) {
    //   this.$swal({
    //     title: "Are you sure?",
    //     text: "Once deleted, you will not be able to recover !",

    //     confirmButtonText: "Delete",
    //     cancelButtonText: "Cancel",
    //     showCancelButton: true,
    //     showCloseButton: true,
    //     everseButtons: true
    //   }).then(willDelete => {
    //     if (willDelete.isConfirmed) {
    //       this.$store.dispatch(DELETE_PROMOCODE, id).then(data => {
    //         this.$toastr.s(data.msg);
    //         this.getPromocodes.splice(index, 1);
    //       });
    //     }
    //   });
    // },
    // editData(id) {
    //   this.$router.push({ name: "client.promocode.edit", params: { id: id } });
    // },
    ViewItem(appId, index) {
      this.currentIndex = index;
      this.packingMaterialAnalysisModalShow = true;
      this.selectedAppId = appId;
      this.loadingData = true;
      // var data = {
      //   id: appId
      // };

      this.$store
        .dispatch(FETCH_SELF_PACKING_MATERIAL_REPORT, appId)
        .then((res) => {
          this.packingMaterialAnalysis = res.data;
          this.loadingData = false;
          this.hasData = true;
          this.key += 1;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    getPromocodesData() {
      var page = this.pagination.current_page;
      this.getLoadingData = true;
      this.$store
        .dispatch(FETCH_PACKING_MATERIAL_REPORTS, page)
        .then((data) => {
          this.filteredPromoCodes = data.data;
          this.pagination.total_records = data.total_record;
          this.pagination.total_page = data.page_range;
          this.pagination.received_per_page_data = data.data.length;
          this.pagination.per_page_data = 15;
          this.getLoadingData = false;
        })
        .catch(() => {
          this.getLoadingData = false;
        });
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(DELETE_PACKING_MATERIAL_REPORT, id)
            .then(() => {
              this.$toastr.s("Deleted Successfully");
              this.getPromocodesData();
            })
            .catch((err) => {
              this.errors = err;
            });
        }
      });
    },
  },
};
</script>

<style></style>
