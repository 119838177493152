<style lang="scss">
#printme {
  visibility: hidden;
}
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 75%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .modal-content {
    min-height: 46rem !important;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="xl"
    v-model="showModal"
    :title="'Doc No.: ' + packingMaterialDetail.doc_no"
  >
    <div class="row" v-if="loadingData">
      <div class="col-12 text-center" style="padding: 10px">
        <loadingSpinner></loadingSpinner>
        <br />
        <h6>Please Wait, we are get the details.</h6>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-6">
            <label for="">Material Name:</label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.material_name
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Sampling Date: </label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.sampling_date
            }}</label>
          </div>

          <div class="col-6">
            <label for="">Supplier: </label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.supplier
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Bill No: </label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.bill_no
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Total Received in Kg: </label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.total_received
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Size </label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.size
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Brand </label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.brand
            }}</label>
          </div>
          <div class="col-6">
            <label for="">Reported Date: </label>
            <label for="" class="detail ml-2">{{
              packingMaterialDetail.reported_date
            }}</label>
            <button @click="print" class="float-right btn btn-info btn-sm">
              Print
            </button>
          </div>
        </div>

        <div class="log mt-2" style="min-height: 25rem !important">
          <div class="row">
            <div class="col-md-12" style="background: white">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th scope="col">Parameter</th>
                    <th scope="col">Specification</th>
                    <th scope="col">Observation</th>
                    <th scope="col">Difference</th>
                    <th scope="col">Remarks</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    !loadingData &&
                    packingMaterialDetail.over_report_detail.length > 0
                  "
                >
                  <tr
                    v-for="(
                      item, index
                    ) in packingMaterialDetail.over_report_detail"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.parameter }}</td>
                    <td>{{ item.specification }}</td>
                    <td>{{ item.observation }}</td>
                    <td>{{ item.difference }}</td>

                    <td>{{ item.remarks }}</td>
                  </tr>
                </tbody>

                <tbody
                  v-if="
                    !loadingData &&
                    packingMaterialDetail.over_report_detail.length < 1
                  "
                >
                  <tr>
                    <td colspan="10" class="text-center">No record(s) found</td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="10">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" id="printme">
      <div class="col-md-12">
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <table
                class="table table-striped"
                style="
                  margin-top: 5px;
                  border: 1px solid black;
                  border-collapse: collapse;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        width: 25%;
                        padding: 10px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: center;
                      "
                      scope="col"
                    >
                      <img
                        width="150"
                        height="100"
                        alt="Logo"
                        src="@/assets/images/logo/pashupati_biscuits.png"
                        class="img-logo"
                      />
                    </th>
                    <th
                      style="
                        width: 45%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding: 10px;
                        text-align: center;
                      "
                      scope="col"
                    >
                      <span
                        >Quality Control Section<br />
                        <hr />
                        Packing Material Analysis Report<br />
                        (Laminate Wrapper, Poly-bags(HM/PP), BOPP, PVC Trays)
                      </span>
                    </th>
                    <th
                      style="
                        width: 30%;
                        padding: 10px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: center;
                      "
                      scope="col"
                    >
                      Doc. No: {{ packingMaterialDetail.doc_no }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <table
                style="
                  margin-top: 20px;
                  border: 1px solid black;
                  border-collapse: collapse;
                  width: 100%;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Material Name
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.material_name }}
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Sampling Date:
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.sampling_date }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Supplier
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.supplier }}
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Bill No:
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.bill_no }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Total Received in Kg.
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.total_received }}
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Size
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.size }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Brand
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.brand }}
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      Reported Date:
                    </th>
                    <th
                      style="
                        width: 25%;
                        padding: 8px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        text-align: left;
                      "
                      scope="col"
                    >
                      {{ packingMaterialDetail.reported_date }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <table
                class="table table-bordered table-striped"
                style="
                  margin-top: 20px;
                  border: 1px solid black;
                  border-collapse: collapse;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        width: 5%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding-top: 5px;
                        padding-bottom: 5px;
                      "
                    >
                      SN
                    </th>
                    <th
                      style="
                        width: 40%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding-top: 5px;
                        padding-bottom: 5px;
                      "
                      scope="col"
                    >
                      Parameter
                    </th>
                    <th
                      style="
                        width: 10%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding-top: 5px;
                        padding-bottom: 5px;
                      "
                      scope="col"
                    >
                      Specification
                    </th>
                    <th
                      style="
                        width: 10%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding-top: 5px;
                        padding-bottom: 5px;
                      "
                      scope="col"
                    >
                      Observation
                    </th>
                    <th
                      style="
                        width: 10%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding-top: 5px;
                        padding-bottom: 5px;
                      "
                      scope="col"
                    >
                      Difference
                    </th>
                    <th
                      style="
                        width: 15%;
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding-top: 5px;
                        padding-bottom: 5px;
                      "
                      scope="col"
                    >
                      Remarks
                    </th>
                  </tr>
                  <tr
                    v-for="(
                      item, index
                    ) in packingMaterialDetail.over_report_detail"
                    :key="index"
                  >
                    <th
                      style="border: 1px solid black; border-collapse: collapse"
                    >
                      {{ index + 1 }}
                    </th>
                    <th
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 5px;
                        text-align: left;

                        /* margin: 10px 0px 10px 0px; */
                      "
                    >
                      {{ item.parameter }}
                    </th>
                    <th
                      style="border: 1px solid black; border-collapse: collapse"
                    >
                      {{ item.specification }}
                    </th>
                    <th
                      style="border: 1px solid black; border-collapse: collapse"
                    >
                      {{ item.observation }}
                    </th>
                    <th
                      style="border: 1px solid black; border-collapse: collapse"
                    >
                      {{ item.difference }}
                    </th>
                    <th
                      style="border: 1px solid black; border-collapse: collapse"
                    >
                      {{ item.remarks }}
                    </th>
                  </tr>
                </thead>

                <tbody
                  v-if="packingMaterialDetail.over_report_detail.length < 1"
                >
                  <tr>
                    <td style="text-align: center" colspan="6">
                      No record(s) found
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="10">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
        </div>
        <div class="log">
          <div class="row">
            <div class="col-md-12">
              <table
                style="
                  margin-top: 3rem;
                  border: 1px solid black;
                  border-collapse: collapse;
                  min-width: 100%;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        padding: 7.5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                        height: 3rem;
                      "
                      scope="col"
                    ></th>
                    <th
                      style="
                        padding: 7.5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                        height: 3rem;
                      "
                      scope="col"
                    ></th>
                  </tr>
                  <tr>
                    <th
                      style="
                        padding: 7.5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                      "
                      scope="col"
                    >
                      Analyst
                    </th>
                    <th
                      style="
                        padding: 7.5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 50%;
                      "
                      scope="col"
                    >
                      Approved by:
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!hasData && !loadingData">
      <div class="col-md-12 col-12" style="padding: 10px">
        <h5>Sorry, we didn't found details</h5>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    indexNum: {
      type: Number,
      default: -1,
    },
    packingMaterialDetail: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  components: {
    loadingSpinner,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
  },
  methods: {
    close() {
      this.$emit("closemodal");
    },
    print() {
      var contents = document.getElementById("printme").innerHTML;
      var frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      var frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(`<html><head>`);
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(contents);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();
      var css = "@page { size: A4 portrait; }",
        head =
          frameDoc.document.head || document.getElementsByTagName("head")[0],
        style = frameDoc.document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(frameDoc.document.createTextNode(css));
      }
      head.appendChild(style);
      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
      return false;
    },
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function (value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      return moment(String(fullDate)).format("h:mm a");
    },
    sDateFormate: function (date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    },
  },
};
</script>
